<template>
  <div>
    <LocationAutocomplete
        :id="'cae-' + index"
        :entity="{location: ''}"
        class="mb-5"
        @selected="onPlaceSelected"
    ></LocationAutocomplete>
    <v-row>
      <v-col md="6">
        <v-text-field
            v-model="address.address1"
            :rules="validation.required"
            class="mini"
            hide-details="auto"
            label="Address 1"
            outlined required dense
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="address.address2"
            class="mini"
            hide-details="auto"
            label="Address 2"
            outlined dense
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
            v-model="address.country_code"
            :items="['US', 'CA']"
            :rules="validation.required"
            class="mini"
            hide-details="auto"
            label="Country"
            outlined persistent-hint required dense
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
            v-model="address.postcode"
            :rules="validation.required"
            class="mini"
            hide-details="auto"
            label="Zip"
            outlined required dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            v-model="address.state"
            class="mini"
            hide-details="auto"
            label="State"
            outlined dense
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model="address.city"
            :rules="validation.required"
            class="mini"
            hide-details="auto"
            label="City"
            outlined required dense
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LocationAutocomplete from "@/components/location/LocationAutocomplete";
export default {
  name: "CounterpartyAddress",
  components: {LocationAutocomplete},
  props: {
    address: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      validation: {
        required: [
          v => !!v || 'Required'
        ]
      }
    }
  },
  methods: {
    onPlaceSelected (addressData, components) {
      let addrNumber = '';
      let addrStreet = '';
      components['address_components'].forEach((item) => {
        if (item.types.includes('street_number')) {
          addrNumber = item['long_name']
        } else if (item.types.includes('route')) {
          addrStreet = item['long_name']
        } else if (item.types.includes('locality')) {
          this.address.city = item['long_name']
        } else if (item.types.includes('administrative_area_level_1')) {
          this.address.state = item['short_name'].toUpperCase()
        } else if (item.types.includes('country')) {
          this.address.country_code = item['short_name'].toUpperCase()
        } else if (item.types.includes('postal_code')) {
          this.address.postcode = item['short_name'].toUpperCase()
        }
      })
      this.address.address1 = (addrNumber ? (addrNumber + ' ') : '') + addrStreet
      this.address.location_coordinates = {
        type: 'Point',
        coordinates: [
          addressData.longitude,
          addressData.latitude,
        ]
      }
      this.address.location_data = components
    }
  }
}
</script>

<style scoped>

</style>
