import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTabs,{attrs:{"show-arrows":"","slider-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VBtn,{staticClass:"mt-1",attrs:{"small":"","text":"","color":"secondary"},on:{"click":_vm.add}},[_vm._v(" Add new address ")]),_c(VSpacer),_vm._l((_vm.createdAddresses),function(address,index){return _c(VTab,{key:'cah-' + index},[_vm._v(" Address - "+_vm._s(index + 1)+" "),_c(VBtn,{attrs:{"x-small":"","text":"","color":"error"},on:{"click":function($event){return _vm.remove(index)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close-box")])],1)],1)})],2),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.counterparty['counterparty_addresses']),function(address,index){return _c(VTabItem,{key:'cac-' + index,staticClass:"mt-5",attrs:{"eager":"","transition":false}},[_c('CounterpartyAddress',{attrs:{"address":address,"index":index}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }